import './ServiceDetails.css'
import TopicTitlePath, { TopicPath } from '../components/parts/TopicTitlePath';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import parse from 'html-react-parser';
import NotFound from '../components/NotFound';
import MenuList from '../components/parts/MenuList';
import Button from '../components/parts/Button';
import { useMediaQuery, mediaQuery } from '../hooks/useMediaQuery';
import { useService } from '../api/useService';
import Loading from '../components/parts/Loading';

export default function ServiceDetails() {
    const { id } = useParams<{ id: string }>();
    const { isLoading, serviceDetails, getDetail } = useService();

    const isSP = useMediaQuery(mediaQuery.sp);

    const navigate = useNavigate();
    const handleBackToList = () => {
        navigate('/service');
        window.scrollTo({ top: 0 });
    };

    useEffect(() => {
        if (id) getDetail(id);
    }, [getDetail, id]);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (!serviceDetails.details.topics_id) {
        return (
            <NotFound link='/service'>事業内容に戻る</NotFound>
        )
    }

    const details = serviceDetails.details;
    const topicPath: TopicPath[] = [
        { name: '株式会社はばたーく', link: '/' },
        { name: '事業内容', link: '/service' },
        { name: details.subject, link: `/service-details/${details.topics_id}` },
    ];

    return (
        <>
            {
                details.topics_id &&
                <>
                    <TopicTitlePath topicSub={details.subject_en} topicMain={details.subject} topicPath={topicPath} />
                    <div className='part-1 flex-col'>
                        {
                            details.topic && parse(details.topic)
                        }
                    </div>

                    {details.menu && <MenuList menu={details.menu} />}
                    
                    <div className='part-2'>
                        <div className='card'>
                            {details.section && details.section.map((content, index) => (
                                <div key={index} id={`section_${index + 1}`} className="section-item">{parse(content)}</div>
                            ))}
                        </div>

                        <div className='back-btn'>
                            {
                                isSP ? (
                                    <Button onClick={handleBackToList} isBackArrow={true} widthPc='320px' heightPc='50px' circleMarginRPc='278px' circleWidthPc='30px' circleMarginRSp='70.667vw' marginTop='14vw'>事業内容に戻る</Button>
                                ) : (
                                    <Button onClick={handleBackToList} isBackArrow={true} widthPc='370px' heightPc='50px' circleMarginRPc='278px' circleWidthPc='30px' circleMarginRSp='70.667vw' marginTop='5vw' >事業内容に戻る</Button>
                                )
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
}