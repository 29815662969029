import './ServiceDetails.css'
import TopicTitlePath, { TopicPath } from '../components/parts/TopicTitlePath';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import parse from 'html-react-parser';
import NotFound from '../components/NotFound';
import { useService } from '../api/useService';
import Loading from '../components/parts/Loading';
import MenuList from '../components/parts/MenuList';

export default function ServicePreview() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('preview_token');
    const { isLoading, serviceDetails, getPreview} = useService();

    useEffect(() => {
        if (token) getPreview(token);
    }, [getPreview, token]);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (!serviceDetails.details.topics_id) {
        return (
            <NotFound link='/service'>事業内容に戻る</NotFound>
        )
    }

    const details = serviceDetails.details;
    const topicPath: TopicPath[] = [
        { name: '株式会社はばたーく', link: '/' },
        { name: '事業内容', link: '/service' },
        { name: details.subject, link: `/service-details/${details.topics_id}` },
    ];

    return (
        <>
            {   
                details.topics_id &&
                <>
                    <TopicTitlePath topicSub={details.subject_en} topicMain={details.subject} topicPath={topicPath} />
                    <div className='part-1 flex-col'>
                        {
                            details.topic && parse(details.topic)
                        }
                    </div>

                    {details.menu && <MenuList menu={details.menu} />}
                    
                    <div className='part-2'>
                        <div className='card'>
                            {details.section && details.section.map((content, index) => (
                                <div key={index} id={`section_${index + 1}`} className="section-item">{parse(content)}</div>
                            ))}
                        </div>
                    </div>
                </>
            }
        </>
    );
}