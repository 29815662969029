import Top from './pages/Top';
import About from './pages/About';
import Service from './pages/Service';
import ServiceDetails from './pages/ServiceDetails';
import ServicePreview from './pages/ServicePreview';
import Solution from './pages/Solution';
import SolutionDetails from './pages/SolutionDetails';
import SolutionPreview from './pages/SolutionPreview';
import Recruit from './pages/Recruit';
import RecruitDetails from './pages/RecruitDetails';
import RecruitPreview from './pages/RecruitPreview';
import News from './pages/News';
import NewsDetails from './pages/NewsDetails';
import NewsPreview from './pages/NewsPreview';
import Contact from './pages/Contact';
import { RouteObject } from 'react-router-dom';
import NotFound from './components/NotFound';

const routes: RouteObject[] = [
  { path: '/', element: <Top /> },
  { path: '/about', element: <About /> },
  { path: '/service', element: <Service /> },
  { path: '/service-details/:id', element: <ServiceDetails /> },
  { path: '/service/preview', element: <ServicePreview /> },
  { path: '/solution', element: <Solution /> },
  { path: '/solution-details/:id', element: <SolutionDetails /> },
  { path: '/solution/preview', element: <SolutionPreview /> },
  { path: '/recruit', element: <Recruit /> },
  { path: '/recruit-details/:id', element: <RecruitDetails /> },
  { path: '/recruit/preview', element: <RecruitPreview /> },
  { path: '/news', element: <News /> },
  { path: '/news-details/:id', element: <NewsDetails /> },
  { path: '/news/preview', element: <NewsPreview /> },
  { path: '/contact', element: <Contact /> },
  { path: '/*', element: <NotFound link='/'>トップに戻る</NotFound> },
];

export default routes;
