/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

type MenuItem = {
  1: string; // Title
  2: string; // ID
};

type MenuListProps = {
  menu?: Record<number, MenuItem>;
};

const MenuList = ({ menu }: MenuListProps) => {
  if (!menu || Object.keys(menu).length === 0) return null;

  const menuContainerStyle = css`
    width: 100%;
    padding: 2vw 6.944vw calc(2vw - 10px) 6.944vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap:wrap;
    gap:5%;

    @media (max-width: 768px) {
      padding: 4vw 6.944vw calc(4vw - 10px) 6.944vw;
      justify-content: space-between;
      gap:3%;
    }
  `;

  const menuItemStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30%;
    height: 3vw;
    background-color:#FFE833; 
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;
    border-radius: 5px;

    @media (max-width: 768px) {
      min-width: 41vw;
      height: 5vw;
      font-size: 2.8vw;
    }

    &:hover {
      color: #FF8900;
      text-decoration: none;
    }
  `;

  const menuLinkStyle = css`
    text-decoration: none;
    color: inherit;
  `;

  return (
    <div css={menuContainerStyle}>
      {Object.entries(menu).map(([key, value], index) => {
        const item = value[1];
        const id = value[2];

        if (!item || !id) return null;

        return (
          <div key={index} css={menuItemStyle}>
            <a href={`#${id}`} css={menuLinkStyle}>
              {item}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default MenuList;
