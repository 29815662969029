import { apiClient } from "../lib/apiClient";
import { useState, useCallback } from "react";
import axios from "axios";

type ServiceListsResponseApi = {
    errors: [];
    messages: [];
    list: ServiceListType[];
}

type OtherServiceListsResponseApi = {
    errors: [];
    messages: [];
    list: OtherServiceListType[];
};

type ServiceDetailResponseApi = {
    errors: [];
    messages: [];
    details: ServiceDetailType;
}

export type MenuItem = {
    1: string;
    2: string;
};

export type ServiceListType = {
    has_details: boolean;
    details_id: number;
    subject: string;
    background_image: {
        id: string;
        url_org: string;
    }
    image1: {
        id: string;
        url_org: string;
    };
    point1_title: string;
    point1_text: string;
    point2_title: string;
    point2_text: string;
    point3_title?: string;
    point3_text?: string;
};

type OtherServiceListType = {
    topics_id: number;
    subject: string;
    image: {
        id: string;
        url_org: string;
    }
    point: string;
    link: string;
};

export type ServiceDetailType = {
    has_details: boolean;
    topics_id: number;
    ymd: string;
    subject: string;
    subject_en: string;
    topic?: string;
    menu?: Record<number, MenuItem>; 
    section?: string[];
};

export const useService = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [serviceLists, setServiceLists] = useState<ServiceListsResponseApi>({
        errors: [],
        messages: [],
        list: [
            {
                has_details: false,
                details_id: 0,
                subject: '',
                background_image: {
                    id: '',
                    url_org: '',
                },
                image1: {
                    id: '',
                    url_org: '',
                },
                point1_title: '',
                point1_text: '',
                point2_title: '',
                point2_text: '',
            }
        ],
    });
    const [serviceDetails, setServiceDetails] = useState<ServiceDetailResponseApi>({
        errors: [],
        messages: [],
        details: {
            has_details: false,
            topics_id: 0,
            ymd: '',
            subject: '',
            subject_en: '',
        },
    });
    const [otherServiceLists, setOtherServiceLists] = useState<OtherServiceListsResponseApi>({
        errors: [],
        messages: [],
        list: [
            {
                topics_id: 0,
                subject: '',
                image: {
                    id: '',
                    url_org: '',
                },
                point: '',
                link: '',
            }
        ],
    })

    const getList = useCallback(async () => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get('/rcms-api/1/service/list', { cancelToken: source.token })
        .then((response) => {
            setServiceLists(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const getOtherList = useCallback(async () => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get('/rcms-api/1/service-other/list', { cancelToken: source.token })
        .then((response) => {
            setOtherServiceLists(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, [])

    const getDetail = useCallback(async (topicsId: string) => {
        const source = axios.CancelToken.source();
        setIsLoading(true);
        await apiClient.get(`/rcms-api/1/service/detail/${topicsId}`, { cancelToken: source.token })
        .then((response) => {
            setServiceDetails(response.data);
        }).catch ((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error:', error);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const getPreview = useCallback(async (token: string) => {
        setIsLoading(true);
        await apiClient.get(`rcms-api/1/service/preview?preview_token=${token}`, {})
        .then((response) => {
            setServiceDetails(response.data)
        }).catch((error) => {
            console.error('Error:', error);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    return { isLoading, serviceLists, getList, otherServiceLists, getOtherList, serviceDetails, getDetail, getPreview };
}